// import customHeaders, {getSpace} from "../../common/headers";
import headers from '../../common/headers';
import add_icon from './img/add.svg';
import AddBalanceModal from './../../components/AddBalanceModal/index';
import { useDataTable } from '../../common/hooks';
import { ButtonBasic, TableBox } from '@lk-gtcom/ecomlab-components';
import { useEffect, useState, useRef } from 'react';
import {
  KonstantinMasterHttps,
  KonstantinMasterHttp,
  ArtemBilling2Https,
  ArtemBilling2Http,
} from './../../fetchUrls';
// import useGeneralStore from '../../store/general';
import initTranslations from '../../i18n';
import './Summary.scss';

const namespaces = ['Summary'];

const Summary = () => {
  // const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  // const headers = {...customHeaders, ...getSpace(selectedSpace)}
  const [isModalBalance, setIsModalBalance] = useState(false);
  const [pivotData, setPivotData] = useState([]);
  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const [i18n, seti18n, ready] = useState({}, { useSuspense: false });

  const translate = i18n?.t;
  const t = (key) => {
    if (typeof translate === 'function') {
      return translate(key);
    }
  };

  const initTranslation = async () => {
    const { i18n } = await initTranslations('en', namespaces);
    seti18n(i18n);
  };

  useEffect(() => {
    initTranslation();
  }, []);

  const fetchTableData = (params, abortController = new AbortController()) => {
    let sort;
    let filters;

    if (params) {
      [sort, filters] = params;
    }

    const orderParam = sort?.[0]
      ? `?order_param=${sort?.[0]}&order_param_direction=${sort?.[1]}`
      : '';

    setLoading(true);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/finance/pivot/spending-on-services${orderParam}`;
      // url = `${ArtemBilling2Https}/api/v1/get-transaction-table`;
    } else {
      // url = `${ArtemBilling2Http}/api/v1/get-transaction-table`;
      url = `${KonstantinMasterHttp}/finance/pivot/spending-on-services${orderParam}`;
    }

    const body = JSON.stringify(
      {
        date_from: '2023-12-31',
        date_to: '2024-01-31',
        filters: [
          ['Название', 'text', '==', 'product_price_parsing'],
          ['Сумма', 'num', '<>', [100000, 300000]],
        ],
        limit: 10,
        page: 1,
        sort: ['Сумма', 'ASC'],
      }

      // filters,
      // limit: paginatorRef.current?.limit,
      // page: paginatorRef.current?.page,
    );

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const fetchPivotData = (abortController = new AbortController()) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/finance/pivot/summary-for-period`;
    } else {
      url = `${KonstantinMasterHttp}/finance/pivot/summary-for-period`;
    }

    const body = JSON.stringify({});

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setPivotData(
          json?.data?.map((el, ind) => ({ ...el, label: json?.headers?.[ind] }))
        );
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
      });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const summaryColors = ['#0C2037', '#51CA64', '#F88600', '#365EFF'];

  return (
    <div className="summary">
      {isModalBalance && (
        <AddBalanceModal setIsModalActive={(e) => setIsModalBalance(false)} />
      )}
      <div className="summary__header">
        <h1 className="summary__title">{t('Summary:Summary')}</h1>
        <ButtonBasic
          green
          width="190px"
          text={
            <div className="content-btn">
              <img src={add_icon} alt="icon" />
              {t('Summary:Top_up_balance')}
            </div>
          }
          onClick={(e) => setIsModalBalance(true)}
        />
      </div>

      <div className="summary__period">
        {/* <h2 className="summary__title_period">Сводная за период</h2> */}
        <div className="summary__items-period">
          {pivotData?.map(({ label, amount, color, symb }, ind) => {
            return (
              <div className="summary__item-period">
                <span className="summary__text-period">{label}</span>
                <p
                  className="summary__text-sum"
                  style={{ color: summaryColors?.[ind] }}
                >
                  {symb ? symb : ''}
                  {amount?.toLocaleString()} ₽
                </p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="summary__table">
        <h2 className="summary__title_period">
          {t('Summary:Spending_on_services')}
        </h2>
        <TableBox ref={paginatorRef} {...tableProps} paginator={true} />
      </div>
    </div>
  );
};

export { Summary };
