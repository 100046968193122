import {
  KonstantionGtcomAuthHttps,
  KonstantionGtcomAuthHttp,
} from '../../fetchUrls';
import google from './img/google.svg';
import yandex from './img/yandex.svg';
import headers from '../../common/headers';
import './ExternalAuthentication.scss';

const ExternalAuthentication = () => {
  const handlerGoogle = (e) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantionGtcomAuthHttps}/v2/gtcom/oauth/google/authorize`;
    } else {
      url = `${KonstantionGtcomAuthHttp}/v2/gtcom/oauth/test-google-authorize`;
    }

    const body = JSON.stringify({
      device_info: window.navigator.userAgent,
    });



    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const url = Object.values(json).join('');
        window.location.assign(url);
      })
      .catch((err) => console.log(err));
  };

  const handlerYandex = (e) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantionGtcomAuthHttps}/v2/gtcom/oauth/yandex/authorize`;
    } else {
      url = `${KonstantionGtcomAuthHttp}/v2/gtcom/oauth/test-yandex-authorize`;
    }

    const body = JSON.stringify({
      device_info: window.navigator.userAgent,
    });


    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const url = Object.values(json).join('');
        window.location.assign(url);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="btn-group__social-media">
      <div className="btn__social-media" onClick={() => handlerGoogle()}>
        <object type="image/svg+xml" data={google} />
        <p className="text">Google</p>
      </div>

      <div className="btn__social-media" onClick={() => handlerYandex()}>
        <object type="image/svg+xml" data={yandex} />
        <p className="text">Yandex</p>
      </div>
    </div>
  );
};

export default ExternalAuthentication;
