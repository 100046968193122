import { KonstantionAuthHttps, KonstantionAuthHttp } from '../fetchUrls';
import Cookies from 'universal-cookie';
import headers from './headers';

// 8 hours
export const TOKEN_LIFETIME_MS = 1000 * 60 * 60 * 8;

export const processTokenResponse = (
  responseJson,
  shouldStayOnPage,
  acceptMail
) => {
  const cookies = new Cookies();
  const {
    access_token,
    refresh_token,
    client_id,
    not_one_account,
    login,
    name,
  } = responseJson;

  let accessTokenExpiryDate = new Date();
  let refreshTokenExpiryDate = new Date();

  const { exp: refreshExpTime } = parseJwt(refresh_token);

  accessTokenExpiryDate.setTime(
    accessTokenExpiryDate.getTime() + TOKEN_LIFETIME_MS
  );
  refreshTokenExpiryDate.setTime(
    refreshTokenExpiryDate.getTime() + refreshExpTime
  );

  if (login) {
    localStorage.setItem('email', login);
  }

  if (name) {
    localStorage.setItem('client_name', name);
  }

  if (client_id) {
    localStorage.setItem('client_id', client_id);
  }
  if (not_one_account !== 'undefined') {
    localStorage.setItem('not_one_account', not_one_account);
  }
  if (access_token) {
    cookies.remove('auth', { path: '/' });
    cookies.set('auth', access_token, {
      expires: accessTokenExpiryDate,
      path: '/',
    });
    localStorage.setItem('auth', access_token);
  }
  if (refresh_token) {
    cookies.remove('refresh', { path: '/' });
    cookies.remove('refresh', { path: `/landing` });
    cookies.set('refresh', refresh_token, {
      expires: refreshTokenExpiryDate,
      path: '/',
    });
    localStorage.setItem('refresh', refresh_token);
  }

  if (login && access_token && refresh_token) {
    if (shouldStayOnPage) {
      window.location.reload();
    } else {
      if (not_one_account || acceptMail) {
        setTimeout(() => {
          window.location.assign(`/top-categories`);
        }, 3000);
      } else {
        window.location.assign(`/top-categories`);
      }
    }
  } else {
    window.location.assign(`/auth`);
  }
};

export const refresh = ({ token, onError, onCleanup, shouldStayOnPage }) => {
  let url;
  if (!window.location.hostname.match('localhost')) {
    url = `${KonstantionAuthHttps}/web/tokens`;
  } else {
    url = `${KonstantionAuthHttp}/web/tokens`;
  }

  const headers = {
    'Content-type': 'application/json',
    Authorization: `Bearer ${token}`,
  };


  fetch(url, { headers })
    .then((res) => {
      return res.json();
    })
    .then((json) => processTokenResponse(json, shouldStayOnPage))
    .catch((err) => {
      console.log(err);
      if (typeof onError === 'function') onError(err);
      else console.error(err);
    })
    .finally(() => (typeof onCleanup === 'function' ? onCleanup() : {}));
};

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
