import BlueCheckbox from '../../common/components/BlueCheckbox/BlueCheckbox';
import ExternalAuthentication from '../ExternalAuthentication/ExternalAuthentication';
import { Link } from 'react-router-dom';
import { Mail } from '../PasswordRecoveryBox/Mail/Mail';
import { useState } from 'react';
import { ModalErr } from '../Modal/ModalErr/ModalErr';
import { validationEmail } from '../../common/validationFunc';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import headers from '../../common/headers';
import './RegistrationBox.scss';

const RegistrationBox = ({ admin = false }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [personalData, setPersonalData] = useState(false);
  const [newsSubscription, setNewsSubscription] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const [inValid, setInvalid] = useState(false);
  const [errPersonalData, setErrPersonalData] = useState(false);

  const [err, setErr] = useState(false);
  const [errPassword, setErrPassword] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errName, setErrName] = useState(false);

  const [isModal, setIsModal] = useState(false);
  const [isModalRegistration, setIsModalRegistration] = useState(false);

  const deleteErrNotofication = () => {
    setErrPassword(false);
    setErr(false);
    setErrEmail(false);
    setInvalid(false);
    setErrName(false);
    setErrPersonalData(false);
  };

  const handlerSetEmail = (e) => {
    setEmail(e);
    deleteErrNotofication();
  };

  const handlerSetPassword = (e) => {
    setPassword(e);
    deleteErrNotofication();
  };

  const handlerSetName = (e) => {
    setName(e);
    deleteErrNotofication();
  };

  const handlerHidePassword = (e) => {
    setHidePassword(!hidePassword);
    deleteErrNotofication();
  };

  const activeTypeIput = hidePassword ? 'text' : 'password';

  // переменная для проверки
  let formBig = {
    name,
    email,
    password,
    mailing_allowed: newsSubscription,
    cookies_allowed: true,
  };

  const handlerSubmit = (e) => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantionAuthHttps}/web/register`;
    } else {
      url = `${KonstantionAuthHttp}/web/register`;
    }


    fetch(url, {
      method: 'POST',
      body: JSON.stringify(formBig),
      headers,
      // headers: {
      //   'Content-type': 'application/json',
      //   accept: 'application/json',
      // },
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((data) => {
        if (data?.detail == 'REGISTER_USER_ALREADY_EXISTS') {
          setErr(true);
        }

        if (data?.detail == 'Not Found') {
          setIsModal(true);
        }

        if (data?.message == 'User successfully registered') {
          setIsModalRegistration(true);
        }
      })
      .catch((err) => {
        setIsModal(true);
        console.log(err);
      });
  };

  const checkInputField = () => {
    const regexPassword =
      /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{6,}/;

    if (!regexPassword.test(password)) {
      setErrPassword(true);
    }

    if (!validationEmail(email)) {
      setErrEmail(true);
    }

    if (!name) {
      setErrName(true);
    }

    if (
      !regexPassword.test(password) ||
      !validationEmail(email) ||
      !name ||
      !personalData
    ) {
      return;
    } else {
      handlerSubmit();
    }
  };

  return (
    <>
      {isModal && (
        <ModalErr isModal={isModal} setIsModal={(e) => setIsModal(e)} />
      )}
      {isModalRegistration && (
        <Mail
          title="Registration was successful"
          text={`Check your email, we have sent you an email to confirm your registration`}
          setIsModal={(e) => setIsModalRegistration(e)}
        />
      )}
      <form
        className="autorization"
        style={{ marginTopp: '150px' }}
        onSubmit={(e) => e.preventDefault()}
        spellCheck="false"
      >
        <div
          className="title_wrapper"
          style={{ display: 'flex', gap: '1em', alignItems: 'center' }}
        >
          <h2 className="title" style={{ marginRight: '1em' }}>
            Registration
          </h2>
        </div>

        <ExternalAuthentication />

        <p className="text">or</p>

        <div className="input-with-btn">
          <InputDinamycPlaceholder
            onChange={(e) => handlerSetName(e)}
            type="text"
            err={errName}
            textErr={'Enter the name'}
            classname="autorization__input-field"
            placeholder="Your name"
            value={name}
            setValue={(e) => setName(e)}
            autoComplete={false}
          />
        </div>
        <div className={inValid ? 'input-with-btn_active' : 'input-with-btn'}>
          <InputDinamycPlaceholder
            onChange={(e) => handlerSetEmail(e)}
            type="e-mail"
            err={errEmail}
            textErr={'Invalid email'}
            classname="autorization__input-field"
            placeholder="Email"
            value={email}
            setValue={(e) => setEmail(e)}
            autoComplete={false}
          />
        </div>

        <InputDinamycPlaceholder
          onChange={(e) => handlerSetPassword(e)}
          type={activeTypeIput}
          classname="autorization__input-field"
          err={errPassword}
          textErr={'Password does not meet the requirements'}
          placeholder="Password"
          value={password}
          setValue={(e) => setPassword(e)}
          passwordTooltip
          autoComplete={false}
          tooltip="Password must contain minimum 8 symbols, numbers and uppercase"
        />

        {err && (
          <div className="err-box">
            <span className="text text_red">
              User with this login already exists
            </span>
          </div>
        )}

        <div className="checkbox__box_registration">
          <label>
            <BlueCheckbox
              err={errPersonalData}
              setErr={(e) => setErrPersonalData(e)}
              value={personalData}
              onChange={(e) => setPersonalData(e)}
            />
            {/* id="personal-data-checkbox" */}
            <span className="text text_gap">
              I agree to
              <Link
                to="#"
                onClick={() =>
                  window.open(
                    'https://gtcom.io/main/article/Terms_of_Service/578',
                    '_blank'
                  )
                }
                className="blue"
              >
                Terms and conditions
              </Link>
            </span>
          </label>

          <label>
            <BlueCheckbox
              value={newsSubscription}
              onChange={(e) => setNewsSubscription(e)}
            />
            <span className="text">
              I want to receive emails with news from GTCOM
            </span>
          </label>
        </div>
        <div className="bottom-box-registration">
          <button
            className="btn__blue"
            onClick={(e) => {
              e.preventDefault();
              if (!personalData) {
                setErrPersonalData(true);
              }
              checkInputField();
            }}
          >
            Sign up
          </button>
          {admin ? (
            <div className="right-header-box">
              <span className="text">Do you have account?</span>
              <Link to="/auth" className="link-box-btn">
                <button className="btn__blue">Log in</button>
              </Link>
            </div>
          ) : null}
        </div>
        <div className="bottom-box">
          <p className="text_grey-blue">Do you have account?</p>
          <Link to="/auth" className="text_blue">
            Log in
          </Link>
        </div>
      </form>
    </>
  );
};

export { RegistrationBox };
