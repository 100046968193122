import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { DropDownSelector } from '@lk-gtcom/ecomlab-components';
import { KonstantionAuthHttp, KonstantionAuthHttps } from '../../../fetchUrls';
import { validationEmail } from '../../../common/validationFunc';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import close_icon from './img/close.svg';
import plus_icon from './img/bx-user-plus.svg.svg';
import Cookies from 'universal-cookie';
import { Checkbox } from '@lk-gtcom/ecomlab-components';
import initTranslations from '../../../i18n';
import headers from '../../../common/headers';
import './ModalAddEmployee.scss';

const namespaces = ['ModalAddEmployee'];

const ModalAddEmployee = ({ setShowModal, fetchGetEmployees, spaceList }) => {
  const [i18n, seti18n, ready] = useState({}, { useSuspense: false });

  const translate = i18n?.t;
  const t = (key) => {
    if (typeof translate === 'function') {
      return translate(key);
    }
  };

  const initTranslation = async () => {
    const { i18n } = await initTranslations('en', namespaces);
    seti18n(i18n);
  };

  useEffect(() => {
    initTranslation();
  }, []);

  const auth = new Cookies().get('auth');
  const [loginExecutor, setLoginExecutor] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);

  const [selected, setSelected] = useState([]);
  const [errSelect, setErrSelect] = useState(false);

  const [checkboxValues, setCheckboxValues] = useState([]);
  const [heightContainer, setHeightContainer] = useState(0);
  const refContainer = useRef();

  const options_prop = [
    {
      label: t('ModalAddEmployee:Administrator'),
      value: 7,
    },
    {
      label: t('ModalAddEmployee:Product_manager'),
      value: 8,
    },
    {
      label: t('ModalAddEmployee:Warehouse_manager'),
      value: 9,
    },
  ];

  const fetchAddEmployee = () => {
    if (selected?.label) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantionAuthHttps}/web/users/employees`;
      } else {
        url = `${KonstantionAuthHttp}/web/users/employees`;
      }

      const body = JSON.stringify({
        client_executor_login: loginExecutor,
        role: selected?.label,
      });



      fetch(`${url}`, { body, method: 'POST', headers })
        .then((res) => res.json())
        .then((json) => {
          fetchGetEmployees();
        })
        .catch((err) => console.log(err));
    }
  };

  const onValidateForm = () => {
    if (!validationEmail(loginExecutor)) {
      return setErrorEmail(true);
    }
    if (!selected?.value) {
      return setErrSelect(true);
    }

    fetchAddEmployee();
    setShowModal(false);
  };

  const heightColumn = () => {
    const height = refContainer?.current?.getBoundingClientRect().height;
    setHeightContainer(height);
  };

  useEffect(() => {
    heightColumn();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', heightColumn);

    return () => {
      window.removeEventListener('resize', heightColumn);
    };
  }, []);

  useEffect(() => {
    setErrSelect(false);
  }, [selected]);

  const optionsPropList = options_prop.map(({ label, value }) => (
    <label
      className="checkbox-container authorization-checkbox__container"
      key={label}
    >
      <input type="checkbox" className="checkbox" checked />
      <span className="checkbox-element "></span>
      <div className="text-and-icon-box">
        <p className="text_icon">{label}</p>
        <p className=""></p>
      </div>
    </label>
  ));

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setShowModal(false);
        }
      }}
    >
      <div className="modal-add-employee">
        <div className="modal-add-employee__header">
          <h3 className="modal-add-employee__title">
            {t('ModalAddEmployee:Adding_an_employee')}
          </h3>
          <h3 className="modal-add-employee__title">
            {t('ModalAddEmployee:Choose_space')}
          </h3>
        </div>

        <button
          className="modal-add-employee__btn-close"
          onClick={(e) => setShowModal(false)}
        >
          <img src={close_icon} />
        </button>

        <div className="modal-add-employee__content">
          <div ref={refContainer} className="modal-add-employee__left-content">
            <InputDinamycPlaceholder
              classname="autorization__input-field"
              err={errorEmail}
              placeholder={t('ModalAddEmployee:enter')}
              textErr="Invalid e-mail"
              type="e-mail"
              autoComplete={false}
              onChange={(e) => {
                setErrorEmail(false);
                setLoginExecutor(e);
              }}
            />
            <label className="checkbox-box">
              <p className="modal-add-employee__text">
                {t('ModalAddEmployee:tooltip')}
              </p>
            </label>

            <DropDownSelector
              options_prop={options_prop}
              state={selected}
              setState={(e) => setSelected(e)}
              placeholder={t('ModalAddEmployee:Select_a_role')}
              err={errSelect}
              width="100%"
            />

            <label className="checkbox-box">
              <Checkbox />
              <p className="text_grey">
                {t('ModalAddEmployee:tooltipCheckbox')}
              </p>
            </label>

            <ButtonBasic
              green
              text={
                <div className="modal-add-employee__content-btn">
                  <img src={plus_icon} />
                  {t('ModalAddEmployee:Invite_an_employee')}
                </div>
              }
              width="100%"
              size="56px"
              onClick={(e) => onValidateForm()}
            />
          </div>
          <div className="modal-add-employee__right-content">
            <div
              className="modal-add-employee__box-space"
              style={{ maxHeight: heightContainer }}
            >
              {spaceList?.length > 0 ? (
                spaceList?.map(({ id, name }, ind) => (
                  <button key={id} className="modal-add-employee__drop-btn">
                    <Checkbox
                      value={checkboxValues.includes(id)}
                      onChange={(e) => {
                        let index = checkboxValues.indexOf(id);
                        if (index === -1) {
                          setCheckboxValues([...checkboxValues, id]);
                        } else {
                          let new_values = checkboxValues.filter(
                            (el) => el != id
                          );
                          setCheckboxValues(new_values);
                        }
                      }}
                    />
                    {name}
                  </button>
                ))
              ) : (
                <p className="modal-add-employee__no-data">
                  {t('ModalAddEmployee:no_data')}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalAddEmployee };
