import Cookies from 'universal-cookie';
import headers from '../../common/headers';
// import customHeaders, { getSpace } from '../../common/headers';
import search_icon from './img/search_icon.svg';
import { LogoBox } from '../Logo/Logo';
import { useDebounce } from '../../common/hooks';
import { ProfileSettings } from '../ProfileSettings/ProfileSettings';
import { NotificationList } from '../NotificationList/NotificationList';
import { NotificationContext } from '../../App';
import {
  SearchGlobal,
  MainMenu,
  ModalNotifications,
} from '@lk-gtcom/ecomlab-components';
import {
  Andrey2Https,
  Andrey2Http,
  ArtemBilling2Https,
  ArtemBilling2Http,
} from '../../fetchUrls';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import './HeaderMain.scss';
// import useGeneralStore from '../../store/general';

const HeaderMain = ({
  activeContent,
  activeSubItems,
  activeItems,
  sidebarItems,
  setActiveItems,
  setActiveSubItems,
  setIsModalKnowledgeBase,
}) => {
  // const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  // const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const { showNotificationBox, setShowNotificationBox } =
    useContext(NotificationContext);

  const [profileName, setProfileName] = useState('');
  const [showProfileSettings, setProfileSettings] = useState(false);
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [balance, setBalance] = useState(0);
  const [currency, setCurrency] = useState('₺');
  const path = useParams();
  const navigate = useNavigate();

  const [isModalSearch, setIsModalSearch] = useState(false);

  const [notifCount, setNotifCount] = useState(0);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const handleClickBalance = () => {
    navigate('/settings/payment');
  };

  const onLogOutHandler = () => {
    const cookies = new Cookies();

    cookies.remove('auth', { path: '/' });
    cookies.remove('refresh', { path: '/' });

    localStorage.removeItem('not_one_account');
    localStorage.removeItem('client_id');
    localStorage.removeItem('email');
    if (localStorage.getItem('auth')) localStorage.removeItem('auth');
    if (localStorage.getItem('refresh')) localStorage.removeItem('refresh');

    navigate('../auth');
  };

  const fetchSearchValue = (
    [search, page = 1],
    abortController = new AbortController()
  ) => {
    if (search?.length >= 3) {
      setSearchData([]);
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${Andrey2Https}/api/v1/gtcom/general_search_string/`;
      } else {
        url = `${Andrey2Http}/api/v1/gtcom/general_search_string/`;
      }

      let [date_from, date_to] = date;

      date_from = new Date(date_from + 86401000)
        ?.toISOString()
        ?.split('T')?.[0];
      date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

      const body = JSON.stringify({
        date_from,
        date_to,
        page,
        limit: 15,
        search_filter: search,
      });

      fetch(url, {
        body,
        method: 'POST',
        headers,
        signal: abortController.signal,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error(JSON.stringify(res.json()));
          }
        })
        .then((json) => {
          let dataObj = {};
          const keys = Object.keys(json);
          keys.forEach((el) => {
            dataObj = { ...dataObj, [el?.toLowerCase()]: json?.[el] };
          });
          setSearchData(dataObj);
        })
        .catch((err) => {
          if (abortController.signal.aborted) return;
          console.error(err);
        });
    }
  };

  const fetchSearchHandler = useDebounce(fetchSearchValue, 1000);

  const onSelect = (search) => {
    const { image, product, nm_id, category, extra, brand, seller } = search;

    if (image) {
      navigate(`/sku-analysis/general?nmid=${nm_id}`);
    }

    if (brand && !image) {
      navigate(`/brand-analysis/goods?query=${extra}`);
    }

    if (category && !image) {
      navigate(`/category-analysis/goods?query=${extra}`);
    }

    if (seller && !image) {
      navigate(`/seller-analysis/goods?query=${extra}`);
    }

    setIsModalSearch(false);
  };

  const fetchBalanceCount = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemBilling2Https}/balance`;
    } else {
      url = `${ArtemBilling2Https}/balance`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then(({ balance, currency }) => {
        setBalance(balance?.toLocaleString());
        setCurrency(currency === 'RUB' ? '₽' : currency);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchBalanceCount();
  }, []);

  useEffect(() => {
    let profile = localStorage.getItem('email');
    setProfileName(profile);
  }, []);

  const closeHeaderItems = (e) => {
    let el = e.target;
    if (
      !el.closest('.profile-box__btn') &&
      !el.closest('.profile-box__user-name')
    ) {
      setProfileSettings(false);
    }

    if (el.closest('.notifications__btn') || el.closest('.notification-list')) {
      if (el.closest('.notification-item') || el.closest('.link-blue')) {
        setShowNotificationBox(false);
      } else {
        setShowNotificationBox(true);
      }
    } else {
      setShowNotificationBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeHeaderItems);
    return () => {
      document.addEventListener('click', closeHeaderItems);
    };
  });

  useEffect(() => {
    const showSearch = (e) => {
      let el = e.target;
      if (!el.closest('.search-global') && !el.closest('.search-btn')) {
        setIsModalSearch(false);
      }
    };
    document.addEventListener('click', showSearch);

    return () => {
      document.addEventListener('click', showSearch);
    };
  }, []);

  return (
    <>
      <div className="main-sidebar">
        <nav className="main-sidebar__nav">
          <div className="logo-and-search">
            <Link
              to="/top-categories"
              className="logo-and-search__link"
              onClick={(e) => {
                setActiveItems(null);
                setActiveSubItems(null);
              }}
            >
              <LogoBox />
            </Link>
            <div className="search-btn-container">
              <button
                className="search-btn"
                onClick={(e) => setIsModalSearch(!isModalSearch)}
              >
                <img src={search_icon} alt="icon" />
              </button>
              {isModalSearch && (
                <SearchGlobal
                  {...searchData}
                  setSearch={(e) => setSearch(e)}
                  onSelect={(e) => onSelect(e)}
                  fetchSearch={(e) => fetchSearchHandler(e)}
                  setSearchData={(e) => setSearchData(e)}
                />
              )}
            </div>
            <MainMenu
              menu={sidebarItems}
              activeItems={activeItems}
              setActiveItems={(e) => setActiveItems(e)}
              activeSubItems={activeSubItems}
              setActiveSubItems={(e) => setActiveSubItems(e)}
            />

            {activeContent !== 'Admin' ? (
              <div className="main-sidebar__group-btn">
                <div className="tooltip-container">
                  <Link
                    className="upload__btn"
                    to="/settings/download-history"
                  ></Link>
                  <div className="tooltip_light-blue">Downloads</div>
                </div>
                <div className="tooltip-container">
                  <a
                    className="message__btn notifications"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsModalKnowledgeBase(true);
                    }}
                  ></a>
                  <div className="tooltip_light-blue">Education</div>
                </div>
                <div className="tooltip-container">
                  <Link className="notifications__btn notifications"></Link>
                  <div className="tooltip_light-blue">Notifications</div>
                  {showNotificationBox && (
                    <ModalNotifications
                      notifCount={notifCount}
                      isOpen={showNotificationBox}
                      setIsOpen={setShowNotificationBox}
                      portal_id={7}
                      activeLang={'en'}
                      // fetchNotificationsCount={e => fetchNotificationsCount()}
                    />
                  )}
                  {notifCount > 0 && (
                    <span className="notification-count">{notifCount}</span>
                  )}
                </div>
              </div>
            ) : null}
          </div>

          <div className="main-sidebar__right-box">
            <div className="balance-box-num" onClick={handleClickBalance}>
              {/* {balance} <span>{` ${'₺'}`}</span> */}
              {balance} <span>{currency}</span>
            </div>

            <div className="profile-box">
              <p className="profile-box__user-name">{profileName}</p>
              <div
                className="profile-list__content"
                onClick={(e) => {
                  setProfileSettings(!showProfileSettings);
                }}
              >
                <button className="profile-box__btn">
                  {profileName && profileName[0]?.toUpperCase()}
                </button>

                {showProfileSettings && (
                  <ProfileSettings
                    setActiveItems={(e) => setActiveItems(e)}
                    setActiveSubItems={(e) => setActiveSubItems(e)}
                    onLogOutHandler={(e) => onLogOutHandler(e)}
                  />
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export { HeaderMain };
