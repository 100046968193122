import headers from '../../common/headers';
import { LineChart } from '../../charts/LineChart/LineChart';
import { SliderRange } from '../Slider/Slider';
import { TooltipLightBlue } from '../Tooltip/TooltipLightBlue/TooltipLightBlue';
import { FiltersBreadCrumbs } from '../FiltersBreadCrumbs/FiltersBreadCrumbs';
import { Andrey2Https, Andrey2Http, AndreySkuHttps } from '../../fetchUrls';
import { useDataTable, useChartData } from '../../common/hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import {
  TableBox,
  DropDownSelector,
  DropContainer,
  RangeBox,
  Tabs,
  CheckboxToggleBlue,
  Checkbox,
} from '@lk-gtcom/ecomlab-components';
import './AnalysisCategory.scss';
import { Helmet } from 'react-helmet-async';
import { addFiltersByDataObj, addFiltersToUrlQuery, getDataObjByFilters, getFiltersByUrlQuery } from '../../common/utils'
import { IndeterminateCheckBoxRounded } from '@mui/icons-material';

const btn_tab = [
  // {
  //     label: 'Top',
  //     value: 'top'
  // },
  {
    label: 'Products',
    value: 'goods',
  },
  {
    label: 'Brand',
    value: 'brands',
  },
  {
    label: 'Sellers',
    value: 'sellers',
  },
  {
    label: 'By days',
    value: 'days',
  },
  {
    label: 'Price segments',
    value: 'price_segment',
  },
];

const options_period = [
  {
    label: 'days',
    value: 'days',
  },
  {
    label: 'week',
    value: 'week',
  },

  {
    label: 'months',
    value: 'months',
  },
];

const AnalysisCategory = ({ }) => {
  const [pageValue, setPageValue] = useState(btn_tab[0].value);

  const paginatorRef = useRef();
  const [setTable, tableProps, setTableFuncs] = useDataTable(
    paginatorRef.current?.setTotal,
    paginatorRef
  );
  const { loading, sort, filters } = tableProps;
  const { setLoading, setFetchedData, setHeaders, setSort, setFilters } =
    setTableFuncs;

  const [setChartData, chartData, setChartFuncs] = useChartData();
  const { setChartLoading, resetChart } = setChartFuncs;

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedItems, setSelectedItems] = useState([]);
  const [isReverse, setIsReverse] = useState(false);
  const [tableMetrics, setTableMetrics] = useState([]);
  const [tableMetric, setTableMetric] = useState('IDC');
  const [choisePrice, setChoisePrice] = useState([]);

  const [labels, setLabels] = useState([]);

  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(20000);
  const [price, setPrice] = useState([minValue, maxValue]);
  const [segments, setSegments] = useState(1);
  const [checkFbs, setCheckFbs] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState(options_period[0]);
  const [query, setQuery] = useState('')
  const [iniitalQuery, setInitialQuery] = useState(true)

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);

  const activeTab = useParams()['*'];

  const [showDropBox, setShowDropBox] = useState(false);
  const [isClick, setIsClick] = useState(false);

  const [selectedBreadcrumbsValue, setSelectedBreadcrumbsValue] = useState([]);
  const [selectedBreadcrumbsValue2, setSelectedBreadcrumbsValue2] =
    useState('');

  const [showMenu, setShowMenu] = useState(false);

  const resetTable = () => {
    paginatorRef.current.reset();
    paginatorRef.current.setTotal(0);
    setSort({});
    setHeaders([]);
    setFetchedData([]);
    resetChart();
    setLabels([]);
  };

  const onHandleSliderChange = (minMax) => {
    setPrice(minMax);
  };

  const fetchAllAccountData = (
    params,
    abortController = new AbortController(),
    selectedBread
  ) => {
    if (!selectedBreadcrumbsValue.at(-1)?.value?.id) return;
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params
    }
    setLoading(true)


    let url
    if (!window.location.hostname.match('localhost')) {
      url = `${Andrey2Https}/api/v2/gtcom/category/${pageValue}/table/${pageValue == 'top' ? '' : 'first_level/'}`
    } else {
      url = `${Andrey2Https}/api/v2/gtcom/category/${pageValue}/table/${pageValue == 'top' ? '' : 'first_level/'}`
    }

    let [date_from, date_to] = date
    date_from = new Date(date_from + 86401000)?.toISOString().split('T')?.[0]
    date_to = new Date(date_to)?.toISOString().split('T')?.[0]

    const priceData = pageValue == 'price_segment'
      ?
      {
        min_price: price[0],
        max_price: price[1],
        number_of_segments: segments
      }
      : {}

    const reverseData = pageValue == 'days'
      ?
      {
        reverse: isReverse,
      }
      : {}

    const body = JSON.stringify({
      category_id_filter: selectedBreadcrumbsValue.at(-1)?.value?.id,
      // nm_id_filter: ,
      limit: paginatorRef.current.limit,
      page: paginatorRef.current.page,
      fbofbs_filter: checkFbs,
      group_type: selectedPeriod?.value,
      stock_type: 'all',
      date_from,
      date_to,
      sort_name: sort?.[0],
      sort_order: sort?.[1],
      filters: filtersParam,
      ...priceData,
      ...reverseData,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTable(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setLoading(false);
      });
  };

  const fetchMinMaxPrice = () => {
    if (selectedBreadcrumbsValue.length) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${Andrey2Https}/api/v2/gtcom/category/price_range/`;
      } else {
        url = `${Andrey2Https}/api/v2/gtcom/category/price_range/`;
      }

      let [date_from, date_to] = date;
      date_from = new Date(date_from + 86401000)?.toISOString().split('T')?.[0];
      date_to = new Date(date_to)?.toISOString().split('T')?.[0];

      const body = JSON.stringify({
        category_filter: selectedBreadcrumbsValue.at(-1)?.value?.id,
        date_from,
        date_to,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const { min_price, max_price } = json;
          setMinValue(min_price);
          setMaxValue(max_price);
          setPrice([min_price, max_price]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchChartData = (abortController = new AbortController()) => {
    setChartLoading(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${Andrey2Https}/api/v2/gtcom/category/${pageValue}/graph/`;
    } else {
      url = `${Andrey2Http}/api/v2/gtcom/category/${pageValue}/graph/`;
    }

    let searchId;
    let [date_from, date_to] = date;
    date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0];
    date_to = new Date(date_to)?.toISOString()?.split('T')?.[0];

    const id_arr = selectedItems?.map((el) => {
      if (el) {
        if (pageValue === 'goods' && el?.['Product']) {
          if (Array.isArray(el?.['Product'])) {
            const [img, name, category, brand, nm_id] = el?.['Product'];
            return el?.['Product'][6];
          }
        }
        if (pageValue === 'sellers' && el?.seller_id) {
          return el?.seller_id;
        }

        if (pageValue === 'category' && el?.category_id) {
          return el?.category_id;
        }
        if (pageValue === 'top') {
          return el?.category_id;
        }
        if (pageValue === 'brands' && el?.brand_id) {
          return el?.brand_id;
        }
        if (pageValue === 'price_segment' && el?.min_price && el.max_price) {
          return [el?.min_price, el?.max_price];
        }
      }
    });

    if (pageValue === 'goods') {
      searchId = { nm_id: id_arr };
    }
    if (pageValue === 'sellers') {
      searchId = { seller_id: id_arr };
    }
    if (pageValue === 'category') {
      searchId = { category_id: id_arr };
    }
    if (pageValue === 'brands') {
      searchId = { brand_id: id_arr };
    }
    if (pageValue === 'top') {
      searchId = { category_id: id_arr };
    }
    if (pageValue === 'price_segment') {
      if (Array.isArray(id_arr) && id_arr?.length > 0) {
        searchId = {
          min_price: id_arr[0][0],
          max_price: id_arr[0][1],
        };
      }
    }

    let categories =
      pageValue == 'top'
        ? ''
        : { category_id_filter: [selectedBreadcrumbsValue.at(-1)?.value?.id] };

    const body = JSON.stringify({
      date_from,
      date_to,
      //  category_id: [selectedBreadcrumbsValue.at(-1)?.value?.id],
      limit: 300,
      page: 1,
      fbofbs_filter: checkFbs,
      metrics: [],
      metric_name: tableMetric,
      ...searchId,
      // ...priceData,
      ...categories,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setChartData(json);
      })
      .catch((err) => {
        resetChart();
        console.log(err);
      })
      .finally(() => {
        if (abortController.signal.aborted) return;
        setChartLoading(false);
      });
  };

  const fetchBreadCrumbs = (parentId, page) => {
    let _page = page,
      limit = 50,
      query = '';
    let url = `${Andrey2Https}/api/v1/general/categories/${!parentId ? '3/' : ''
      }`;
    let options;

    if (!parentId) {
      _page = 1;
      options = { method: 'GET', headers };
    } else {
      const body = JSON.stringify({
        shop_id: 3,
        parent_id: parentId,
        page: _page,
        limit,
      });
      options = { body, method: 'POST', headers };
    }

    return fetch(url, options);
  };

  const fetchBreadCrumbsSearch = (
    query,
    page = 1,
    abortController = new AbortController()
  ) => {
    let _page = page,
      limit = 25;
    let url = `${AndreySkuHttps}/api/v1/general/categories/search/`;

    const body = JSON.stringify({
      limit,
      page: _page,
      shop_id: 3,
      query,
    });

    let options = {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    };

    return fetch(url, options);
  };

  const fetchMetricsList = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${Andrey2Https}/api/v2/gtcom/category/${pageValue}/metric_list/`;
    } else {
      url = `${Andrey2Http}/api/v2/gtcom/category/${pageValue}/metric_list/`;
    }

    fetch(url, { method: 'GET', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setTableMetrics(json);
        setTableMetric(json[0]?.value);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const splitQueryByCategory = (query) => {
    const arr = query?.split('_');
    const current_arr = [...arr?.slice(1, arr?.length), arr?.[0]];
    const formatter = (arr, ind) =>
      current_arr?.map((el, ind) => {
        let val_current = el;
        let parrent_current = 'initial';
        if (ind == 1) {
          parrent_current = current_arr[ind - 1];
          val_current = parrent_current + '_' + val_current;
        }
        if (ind == 2) {
          parrent_current = current_arr[ind - 2] + '_' + current_arr[ind - 1];
          val_current = val_current + '_' + parrent_current;
        }
        if (ind == 3) {
          parrent_current =
            current_arr[ind - 3] +
            '_' +
            current_arr[ind - 2] +
            '_' +
            current_arr[ind - 1];
          val_current = val_current + '_' + parrent_current;
        }

        return {
          label: el,
          parent_id: parrent_current,
          value: {
            id: val_current ? val_current : '',
          },
        };
      });

    const lastLvl = arr?.[0];
    const res = [...arr?.slice(1, arr?.length), lastLvl];
    setSelectedBreadcrumbsValue(formatter(res));
  };

  console.log(selectedBreadcrumbsValue)

  useEffect(() => {
    const regexPassword = /!0026!/g;
    const regexp_2 = /%210026%21/g
    const params = new URL(document.location).searchParams;
    const query_name = (params.get('query_name')?.replace(regexPassword, '&')?.replace(regexp_2, '&'))
    const query_value = (params.get('query_value')?.replace(regexPassword, '&')?.replace(regexp_2, '&'))

    console.log(query_value)

    if (query_name) {
      splitQueryByCategory(query_value);
    }
  }, []);

  useEffect(() => {
    fetchAllAccountData();
  }, [selectedBreadcrumbsValue]);

  useEffect(() => {
    setPageValue(activeTab);
    resetChart();
  }, [activeTab]);

  useEffect(() => {
    const abortController = new AbortController();
    if (pageValue == 'top') {
      fetchAllAccountData([], abortController);
    }

    if (selectedBreadcrumbsValue.at(-1)?.value?.id) {
      if (pageValue === 'price_segment') {
        fetchMinMaxPrice();
      }

      if (pageValue !== 'price_segment') {
        fetchAllAccountData([], abortController);
      }
    } else resetTable();
    return () => abortController.abort();
  }, [
    date,
    pageValue,
    selectedPeriod,
    selectedBreadcrumbsValue,
    checkFbs,
    isReverse,
    selectedBreadcrumbsValue2,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (
      pageValue == 'goods' ||
      pageValue == 'sellers' ||
      pageValue == 'brands' ||
      pageValue == 'days' ||
      pageValue == 'price_segment'
    ) {
      if (selectedBreadcrumbsValue.at(-1)?.value?.id) {
        if (pageValue != 'days' && selectedItems?.length > 0)
          fetchChartData(abortController);
        if (pageValue == 'days') fetchChartData(abortController);
      }
    }

    if (pageValue == 'top') {
      fetchChartData(abortController);
    }

    return () => abortController.abort();
  }, [
    chartData?.selectedLegend,
    selectedItems,
    date,
    pageValue,
    selectedPeriod,
    checkFbs,
    tableMetric,
    choisePrice,
  ]);

  useEffect(() => {
    fetchMetricsList();
  }, [pageValue]);

  const onRedirect = (e) => {
    const [__, link] = e;
    if (Array.isArray(link)) {
      window.open(link?.[1], '_blank');
    } else {
      window.open(link, '_blank');
    }
  };

  const handleCheck = (data) => {
    setSelectedItems(data);
  };

  // const onAction = (e) => {
  //     setChoisePrice([e.min_price, e.max_price])
  // }

  useEffect(() => {
    setSelectedItems([]);
    setChoisePrice([]);
    setSelectedItems([]);
    setChartData([]);
  }, [pageValue, selectedBreadcrumbsValue]);

  useEffect(() => {
    resetTable();
    resetChart();
  }, [pageValue]);

  useEffect(() => {
    const query_name = searchParams?.get('query_name')
    const query_value = searchParams?.get('query_value')
    const query_string = (query_name ? `?query_name=${query_name}` : '') + (query_value ? `&query_value=${query_value}` : '')
    setQuery(query_string)
  }, []);

  useEffect(() => {
    if (iniitalQuery) {
      const dataObj = getFiltersByUrlQuery(searchParams);
      addFiltersByDataObj({
        dataObj,
        setSelectedBreadcrumbsValue
      });
      setInitialQuery(false);
    }
  }, [searchParams]);

  //Запись
  useEffect(() => {
    if (iniitalQuery) return;
    const dataObj = getDataObjByFilters({
      selectedBreadcrumbsValue
    });
    addFiltersToUrlQuery(dataObj, setSearchParams);
  }, [
    selectedBreadcrumbsValue
  ]);

  return (
    <div className="analysis-category">
      <Helmet>
        <title>Category analysis</title>
      </Helmet>
      <div className="analysis-category__content">
        <div className="toolbar-top">
          <FiltersBreadCrumbs
            showMenu={showMenu}
            setShowMenu={(e) => setShowMenu(e)}
            selectedBreadcrumbsValue={selectedBreadcrumbsValue}
            setSelectedBreadcrumbsValue={setSelectedBreadcrumbsValue}
            fetchCallback={fetchBreadCrumbs}
            mapFunc={(el) => ({
              label: el.category_name,
              value: { id: el.category_id, hasChildren: el.has_children },
            })}
            fetchSearch={fetchBreadCrumbsSearch}
          />

          <TooltipLightBlue
            text={
              <p className="text_tooltip">
                FBO (sales from the site's warehouse) is the default. Click on
                the switch to see FBS (sales from the site's warehouse)
              </p>
            }
            top="32px"
            left="0px"
            maxWidth="110px"
            child={
              <label className="checkbox-container">
                <CheckboxToggleBlue
                  value={checkFbs}
                  onChange={(e) => setCheckFbs(!checkFbs)}
                />
                <p className="text_label">FBS</p>
              </label>
            }
          />
        </div>

        <div className="toolbar-medium">
          <Tabs
            query={query}
            pageValue={pageValue}
            tabs={btn_tab} />

          <div className="range-group-btn-and-range-box">
            {pageValue == 'date' && (
              <div className="drop-box">
                <p className="text">By</p>
                <DropDownSelector
                  options_prop={options_period}
                  state={selectedPeriod}
                  setState={(e) => setSelectedPeriod(e)}
                  defaultValue={selectedPeriod}
                  className="connections_page_selector"
                  isClearable={false}
                />
              </div>
            )}
            <RangeBox
              lang="en"
              date={date}
              setDate={(e) => setDate(e)}
              minMaxDate={[1, new Date().setHours(0, 0, 0, 100)]}
            />
          </div>
        </div>

        {
          <DropContainer
            lang='en'
            showDropBox={showDropBox}
            setShowDropBox={(e) => setShowDropBox(e)}
            name={chartData?.title}
          >
            <div className='charts-container'>
              <LineChart
                {...chartData}
                legendPosition='top'
                lang='en'
                selectedMetric={tableMetric}
                setSelectedMetric={setTableMetric}
                metricsList={tableMetrics}
              />
            </div>
          </DropContainer>
        }

        {pageValue === 'price_segment' && (
          <SliderRange
            minValue={minValue}
            maxValue={maxValue}
            segments={segments}
            setSegments={(e) => setSegments(e)}
            setState={(e) => onHandleSliderChange(e)}
            callbackHandler={(e) => {
              setIsClick(!isClick);
              resetChart();
              fetchAllAccountData(e);
            }}
            lang="en"
          />
        )}
      </div>

      <TableBox
        lang="en"
        {...tableProps}
        date={date}
        paginator={true}
        onAction={(e) => onRedirect(e)}
        onCheck={(e) => handleCheck(e)}
        onUncheckAllDependence={[pageValue, selectedBreadcrumbsValue, isClick]}
        ref={paginatorRef}
        // isInsideTable={true}
        maxSelectedCount={pageValue == 'price_segment' ? 1 : 2000}
        onRedirect={onRedirect}
        sortingFunc={(e) => fetchAllAccountData(e)}
        fetchCallback={(e) => fetchAllAccountData(e)}
        child={
          <div
            style={{
              display: 'flex',
              gap: '.5em',
              flexDirection: 'column',
              boxSizing: 'border-box',
              padding: '.5em 0 1em',
            }}
          >
            {pageValue === 'days' && (
              <div>
                <p style={{ display: 'flex', gap: '.5em' }}>
                  Turn over
                  <Checkbox
                    value={isReverse}
                    onChange={(checked) => setIsReverse(checked)}
                  />
                </p>
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export { AnalysisCategory };
