import { useState, useEffect, useRef, useCallback } from 'react'
import { AndreyHttps } from '../fetchUrls'
import headers from './headers'


export function useDebounce(callback, delay) {
    const timer = useRef()
    const [prevId, setPrevId] = useState(null)

    const debounceCallback = useCallback((...args) => {
        const id = args?.[args?.length - 1]?.attribute_id
        if (timer.current) {
            if(prevId === id){
                clearTimeout(timer.current)
            }
        }
        setPrevId(id)
        timer.current = setTimeout(() => {
            callback(...args)
        }, delay)
    }, [callback, delay, prevId])

    return debounceCallback
}


export const useCompetitorShops = (typeId) => {
    const [shops, setShops] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchShops = () => {
        setLoading(true)


        const abortController = new AbortController()

        fetch(`${AndreyHttps}/shops/${typeId}/`, { headers, method: 'GET', signal: abortController.signal })
        .then(response => response.json())
        .then(json => {
            setLoading(false)
            setShops(json.map(shop => ({ label: shop.name, value: shop.id })))
        })

        return abortController
    }

    useEffect(() => {
        const abortController = fetchShops()
        
        return () => {
            if (abortController) { abortController.abort() }
        }
    }, [])

    return [shops, loading]
}

export const useDataTable = (setTotal, ref) => {
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [columnSize, setColumnSize] = useState([]);
    const [sort, setSort] = useState({});
    const [total, setTotalValue] = useState(0);
    const [filters, setFilters] = useState([]);
    const [nestedUrl, setNestedUrl] = useState('');
  
    const set = (json) => {
      const { data, labels, total, sort, filters, column_size, next_table_url } =
        json;
      if (total) {
        if (typeof setTotal !== 'function') {
          if (ref?.current) {
            ref?.current?.setTotal(total);
          }
        } else {
          setTotal(total);
        }
        setTotalValue(total);
      }
      if (data) setFetchedData(data);
      if (labels) setHeaders(labels);
      if (column_size) setColumnSize(column_size);
      if (sort) setSort(sort);
      if (filters) setFilters(filters);
      if (next_table_url) setNestedUrl(next_table_url);
    };
  
    return [
      set,
      {
        loading,
        nestedUrl,
        fetchedData,
        headers,
        columnSize,
        sort,
        total,
        filters,
      },
      {
        setLoading,
        setFilters,
        setFetchedData,
        setHeaders,
        setColumnSize,
        setSort,
      },
    ];
  };

export const useChartData = ( ) => {
    const [datasets_prop, setDatasets] = useState([])
    const [labels_prop, setLabels] = useState([])
    const [data_name, setDataName] = useState([])
    const [multiAxisData_prop, setMultiAxisData] = useState([])
    const [multiAxisKeys_prop, setMultiAxisKeys] = useState([])
    const [multiAxisUnits, setMultiAxisUnits] = useState([])
    const [type, setType] = useState([])
    const [title, setTitle] = useState('')
    const [loading, setChartLoading] = useState(false)
    const [legend, setLegend] = useState([])
    const [order, setOrder] = useState([])
    const [selectedLegend, setSelectedLegend] = useState([])

    const resetChart = () => {
        setDatasets([])
        setDataName([])
        setMultiAxisData([])
        setMultiAxisKeys([])
        setMultiAxisUnits([])
        setLabels([])
        setType([])
        setTitle('')
      //  setSelectedLegend([])
    }

    const set = (json) => {
        if(json?.datasets){
            const { all_metrics, datasets: { data, label, multiAxisData, multiAxisKeys, multiAxisUnit, type }, labels, title } = json
    
            if(all_metrics) {
                if(typeof setLegend === 'function'){
                    if(all_metrics?.length > 0){
                        setLegend(all_metrics)
                    } else {
                        setLegend([])
                        resetChart()
                    }
                }
                if(typeof setSelectedLegend === 'function'){
                    if(all_metrics?.length > 0){
                        setSelectedLegend(all_metrics)
                    }
                }
            }

            if(title) setTitle(title)
            if(Array.isArray(type) && type?.length > 0) setType(type)
            if(Array.isArray(order) && order?.length > 0) setOrder(order)
            if(Array.isArray(data) && data?.length > 0) setDatasets(data)
            if(Array.isArray(label) && label?.length > 0) setDataName(label)
            if(Array.isArray(labels) && labels?.length > 0) setLabels(labels)
            if(Array.isArray(multiAxisData) && multiAxisData?.length > 0) setMultiAxisData(multiAxisData)
            if(Array.isArray(multiAxisKeys) && multiAxisKeys?.length > 0) setMultiAxisKeys(multiAxisKeys)
            if(Array.isArray(multiAxisUnit) && multiAxisUnit?.length > 0) setMultiAxisUnits(multiAxisUnit)                    
        }
    }

    return [
        set,
        {
            datasets_prop, labels_prop, data_name, 
            multiAxisData_prop, multiAxisKeys_prop, 
            multiAxisUnits, type, title, loading,
            legend, selectedLegend, setSelectedLegend
        },
        { setChartLoading, resetChart }
    ]
}