import Cookies from 'universal-cookie';
import { refresh } from '../../common/authHelper';

export const setUpFetchProxy = () => {
  window.fetch = new Proxy(window.fetch, {
    apply: function (target, thisArg, args) {
      let fetchCall = target.apply(thisArg, args);
      const [url] = args;

      fetchCall.then((res) => {
        if (res.status === 401) {
          if (url.endsWith('/web/tokens')) {
            // выкидываем с портала если не отработал запрос на обновление токенов
            window.location.assign('/auth');
          } else {
            // для всех других запросов обновляем токен
            const refreshToken = new Cookies().get('refresh');
            refresh({
              token: refreshToken,
              onError: () => window.location.assign('/auth'),
              shouldStayOnPage: true,
            });
          }
        }
      });
      return fetchCall;
    },
  });
};
